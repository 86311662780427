import 'jquery.role/lib/jquery.role'
import 'select2'

class SelectTwo {
  constructor() {
    $('@select2').select2({
      theme: "theme",
      width: "100%",
      minimumResultsForSearch: 10,
      templateResult: function (val) {
        let text = '<span>' + val.text + '</span>';
          if (parseInt(val.text, 10) > 15000) {
            text += '<span style="color: red">repeat borrowers only</span>';
          }
          return $('<span style="display: flex; justify-content: space-between; align-items: center;">' + text + '</span>')
          }
        })

    $('@floatSelect2').select2({
      theme: "float",
      width: "100%",
      minimumResultsForSearch: 10
    })
  }
}

export default SelectTwo;
