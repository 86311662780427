import slider from 'bootstrap-slider';

class Calculator {
  constructor(target) {
    this.target = target

    this.current_step = null

    this.$amountSlider = $(target).find('@amountSlider')
    this.$amountSliderMove = $(target).find('@amountSliderMove')
    this.$amountSliderMoveToRepeat = $(target).find('@amountSliderMoveToRepeat')
    this.$termSlider = $(target).find('@termSlider')

    this.$defaultAmountWas = $(target).find('@defaultAmountWas')
    this.$defaultAmountWasShown = $(target).find('@defaultAmountWasShown')
    this.$defaultAmountChangedTo = $(target).find('@defaultAmountChangedTo')
    this.$maximumAmountShown = $(target).find('@maximumAmountShown')
    this.$maximumAmountShownToRepeat = $(target).find('@maximumAmountShownToRepeat')

    this.$creditLimitation = $(target).find('@creditLimitation')
    this.$totalValue = $(target).find('@totalValue')

    this.$needAmount = $(target).find('@needAmount')
    this.$forTerms = $(target).find('@forTerms')

    this.$calcAmount = $(target).find('@calcAmount')
    this.$calcInterest = $(target).find('@calcInterest')
    this.$calcTotal = $(target).find('@calcTotal')
    this.$calcOldTotal = $(target).find('@calcOldTotal')
    this.$paymentDueDateLabel = $(target).find('@paymentDueDateLabel')

    // slider amount plus minus
    this.$minusAmountBtn = $(target).find('@sliderAmountMinus')
    this.$plusAmountBtn = $(target).find('@sliderAmountPlus')
  }

  init() {
    if ($(location).attr('href').indexOf("steps/4") >= 0) {
      this.current_step = 4
    }

    this.initializeSliderValues()
    this._setLaonLimitation()
    this.initializeSliders()
    this.countCalcValues()

    var that = this
    this.$minusAmountBtn.off('click').on('click', that._minusAmountBtn.bind(event, that))
    this.$plusAmountBtn.off('click').on('click', that._plusAmountBtn.bind(event, that))

    $(this.target).off('click', '@sliderCollapseLink').on('click', '@sliderCollapseLink', function(e) {
      this.$amountSlider.slider('relayout')
      $(this).text(function(i, text) {
        if (text == $(this).data('original')) {
          $(this).data('replace')
        } else {
          $(this).data('original')
        }
      })
    })
  }

  initializeSliderValues() {
    this.TERM_VALUES = gon.calculator['term']
    this.TERM_MIN = this.TERM_VALUES[0]
    this.TERM_MAX = this.TERM_VALUES.slice(-1)[0]
    this.LOAN_VALUES = gon.calculator['amount']
    this.LOAN_MIN = this.LOAN_VALUES[0]
    this.LOAN_MAX = this.LOAN_VALUES.slice(-1)[0]
    this.DENOMINATOR = gon.calculator['denominator']
    this.CONDITIONALVALUE = gon.calculator['amountLimitation']
    this.COMMISSIONS = gon.calculator['commissions']
    this.FEES = gon.calculator['commissionsWithFees']

    this.AMOUNT_STEP = (this.LOAN_MAX - this.LOAN_MIN) / this.LOAN_VALUES.length
    this.TERM_STEP = (this.TERM_MAX - this.TERM_MIN) / this.TERM_VALUES.length
  }

  initializeSliders() {
    if (this.$amountSlider.length === 0) return;
    this._calculatePaymentDueDate()
    this.initializeAmountSlider()
  }

  _calculatePaymentDueDate() {
    let value = parseFloat(this.$termSlider.val())
    const date = new Date()

    date.setDate(date.getDate() + value)

    value = String(String(date.getDate()).padStart(2, '0') + '.' + (date.getMonth() + 1)).padStart(2, '0') + '.' + date.getFullYear()

    this.$paymentDueDateLabel.text(value)
  }

  calcPosition(array) {
    var step = 100 / (array.length - 1)
    var positions = [0]
    var i = 0
    while (i < 100) {
      i = i + step
      positions.push(parseInt(i))
    }
    positions.push(100)
    return positions
  }

  initializeAmountSlider() {
    var that = this
    this.$amountSlider.slider({
      min: this.LOAN_MIN,
      max: this.LOAN_MAX,
      ticks: this.LOAN_VALUES,
      ticks_positions: this.calcPosition(this.LOAN_VALUES),
      tooltip: 'hide',
      // formatter: function(value) {
      //   var tipText = value > this.CONDITIONALVALUE ? " " : '';
      //   return that.customToLocaleString(value) + " Rp \n " + tipText;
      // }
    }).on('change', (function(_this) {
      return function(event) {
        var newValue = _this.amountScale(_this.LOAN_VALUES, event.value.newValue);

        if (newValue > _this.CONDITIONALVALUE) {
          _this.$creditLimitation.addClass('active')
          // _this.amount_slider_move_to_repeat()
          _this.$creditLimitation.text(gon.translations['js.slider.tip'])
        } else {
          _this.$creditLimitation.removeClass('active')
        }

        _this.$amountSlider.slider('setValue', newValue)
        _this.$needAmount.text(_this.customToLocaleString(newValue) + " ")
        _this._calculatePaymentDueDate()
        _this.countCalcValues()
        _this.amount_slider_move()
        _this._setDefaultAmountMoved(Number(_this.$defaultAmountWas.val()), newValue)
      };
    })(this));

    this.$maximumAmountShown.val(this.CONDITIONALVALUE)
    this.$defaultAmountWas.val(this.$amountSlider.val())
    this.$defaultAmountWasShown.val(this.$amountSlider.val())
    this.$maximumAmountShownToRepeat.val(this.LOAN_MAX)
  }

  countCalcValues() {
    var amount = parseFloat(this.$amountSlider.val())
    var term = parseFloat(this.$termSlider.val())
    // var daysCount = parseFloat(this.$termSlider.val())
    // var interest = parseFloat(amount * 0.01 * daysCount)
    // var total = parseFloat(amount + interest)
    //var interest = parseFloat(this.COMMISSIONS[amount][parseFloat(term)]['interestFee'])
    var interest = 0
    var interestWithFee = parseFloat(this.FEES[amount][parseFloat(term)]['totalFinanceCharges'])
    var total = parseFloat(amount)
    var old_total = parseFloat(amount + interestWithFee)

    this.$calcAmount.text(this.customToLocaleString(amount))
    this.$calcInterest.text(this.customToLocaleString(interest) + ' %')
    this.$calcTotal.text(this.customToLocaleString(total))
    this.$calcOldTotal.text(this.customToLocaleString(old_total))
    this.$forTerms.text(term)
  }

  amountScale(array, value) {
    return array.reduce(function(prev, curr) {
      if (Math.abs(curr - value) < Math.abs(prev - value)) {
        return curr
      } else {
        return prev
      }
    })
  }

  amount_slider_move_to_repeat() {
    this.$amountSliderMoveToRepeat.val(1)
  }

  amount_slider_move() {
    this.$amountSliderMove.val(1)
  }

  amountPlus() {
    var getValue = parseFloat(this.$amountSlider.val())
    var value = this.LOAN_VALUES[($.inArray(getValue, this.LOAN_VALUES) + 1) % this.LOAN_VALUES.length]
    if (getValue < this.LOAN_MAX) {
      this.$amountSlider.val(value)
      this.$amountSlider.slider('setValue', value, false, true)
    }
  }

  amountMinus() {
    var getValue = parseFloat(this.$amountSlider.val())
    var value = this.LOAN_VALUES[($.inArray(getValue, this.LOAN_VALUES) - 1 + this.LOAN_VALUES.length) % this.LOAN_VALUES.length]
    if (getValue > this.LOAN_MIN) {
      this.$amountSlider.val(value)
      this.$amountSlider.slider('setValue', value, false, true)
    }
  }

  _minusAmountBtn(that, event) {
    that.amountMinus()
    // that.showInfoText(that.$calculator_amount_info)
    event.preventDefault()
  }

  _plusAmountBtn(that, event) {
    that.amountPlus()
    // that.showInfoText(that.$calculator_amount_info)
    event.preventDefault()
  }

  _setLaonLimitation() {
    if (this.current_step) {
      this.LOAN_VALUES = gon.calculator['amount'].filter(num => num <= this.CONDITIONALVALUE)
      this.LOAN_MIN = this.LOAN_VALUES[0]
      this.LOAN_MAX = this.LOAN_VALUES.slice(-1)[0]
    }
  }

  _setDefaultAmountMoved(default_amount_was, new_amount) {
    var new_enum

    if (default_amount_was == new_amount) {
      new_enum = 0
    } else if (default_amount_was < new_amount) {
      new_enum = 1
    } else if (default_amount_was > new_amount) {
      new_enum = -1
    }

    this.$defaultAmountChangedTo.val(new_enum)
  }

  customToLocaleString(value) {
    return value.toLocaleString('ta-LK', {})
  }
}

export default Calculator;
