import 'jquery-validation/dist/jquery.validate.js'
import 'jquery-validation/dist/additional-methods.js'

class JqueryValidation {
  constructor() {
  }

  init() {
    // basic js validation messages
    jQuery.extend(jQuery.validator.messages, {
      promo: gon.translations['errors.messages.promo'],
      required: gon.translations['errors.messages.required'],
      remote: gon.translations['errors.messages.remote'],
      email: gon.translations['errors.messages.email'],
      url: gon.translations['errors.messages.url'],
      date: gon.translations['errors.messages.date'],
      dateISO: gon.translations['errors.messages.dateISO'],
      number: gon.translations['errors.messages.number'],
      digits: gon.translations['errors.messages.digits'],
      creditcard: gon.translations['errors.messages.creditcard'],
      equalTo: gon.translations['errors.messages.equalTo'],
      accept: gon.translations['errors.messages.accept'],
      maxlength: jQuery.validator.format(`${gon.translations['errors.messages.maxLength'] } {0} ${ gon.translations['errors.messages.characters']}`),
      minlength: jQuery.validator.format(`${gon.translations['errors.messages.minLength'] } {0} ${ gon.translations['errors.messages.characters']}`),
      rangelength: jQuery.validator.format(`${gon.translations['errors.messages.range_length_part_one'] } {0} ${ gon.translations['errors.messages.and'] } {1} ${ gon.translations['errors.messages.range_length_part_three']}`),
      range: jQuery.validator.format(`${gon.translations['errors.messages.range_part'] } {0} ${ gon.translations['errors.messages.and'] } {1} `),
      max: jQuery.validator.format(`${gon.translations['errors.messages.max'] } {0}.`),
      min: jQuery.validator.format(`${gon.translations['errors.messages.min'] } {0}.`)

    })

    jQuery.validator.setDefaults({

      errorClass: 'form__error',
      errorElement: 'span',
      errorPlacement: function (error, element) {
        if (element.hasClass('select'))
         error.insertAfter(element.next('span'))
        else if (element.hasClass('boolean'))
         error.insertBefore(element)
        else
         error.insertAfter(element)
       },
      highlight: function (element, errorClass, validClass) {
          $(element).closest('.form__group').addClass('form__group-errored')
          $(element).siblings('.form__error').remove()
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).closest('.form__group').removeClass('form__group-errored')
        $(element).siblings('.form__error').remove()
      }
    })

    $('select').on('change', function () {
      $(this).closest('.form__group').removeClass('form__group-errored')
    })

    $.validator.addMethod('lettersonly', function (value, element) {
      return this.optional(element) || /^[a-zA-Z\s]*$/.test(value)
    }, gon.translations['errors.messages.lettersOnly'])

    $.validator.addMethod('length_10_letters', function (value, element, params) {
      const number = value.replace(/\D/g, '')
      const number_words_length = number.length

      return number_words_length == 10
    }, gon.translations['errors.messages.length10Letters'])

    $.validator.addMethod('mobilePhone', function (value, element, params) {
      const regex = /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
      const number = value.replace(/\D/g, '')

      return regex.test(number)
    }, gon.translations['errors.messages.mobilePhone'])

    $.validator.addMethod('companyPhone', function (value, element, params) {
      const regex = /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/
      const number = value.replace(/\D/g, '')

      return regex.test(number)
    }, gon.translations['errors.messages.companyPhone'])

    $.validator.addMethod('lkDate', function (value, element) {
      return this.optional(element) || value.match(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
    }, gon.translations['errors.messages.dateOfBirth'])

    $.validator.addMethod('doc', function (value, element) {
      return this.optional(element) || value.match(/^[a-zA-Z0-9]+$/)
    }, gon.translations['errors.messages.nic'])

    $.validator.addMethod('validNic', function (value, element, params) {
      let date_of_birth, gender, check_parts, countDate, date, dateOfBirth, dateOfBirthParts, firstJanuary, birthdayDate, leap_year, year

      date_of_birth = $('input#application_date_of_birth')[0]
      gender = $('input:checked')[0]
      const nicNumber = value

      if ((date_of_birth.value != null) && (date_of_birth.value.length === 10) && (gender.value != null)) {
        dateOfBirth = date_of_birth.value
        dateOfBirthParts = dateOfBirth.split('-')
        check_parts = []

        date = new Date((dateOfBirthParts)[0], dateOfBirthParts[1] - 1, dateOfBirthParts[2])
        birthdayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        firstJanuary = new Date(date.getFullYear(), 0, 0)
        countDate = Math.ceil((birthdayDate - firstJanuary) / 864e5)

        year = date.getFullYear()
        leap_year = ((year % 4 === 0 && year % 100 !== 0) || (year % 100 === 0 && year % 400 === 0))

        if ((countDate > 59) && !leap_year)
          countDate = countDate + 1

        if (nicNumber.length === 10) {
          if (nicNumber.slice(-1).toUpperCase() === 'V' || nicNumber.slice(-1).toUpperCase() === 'X') {
            check_parts.push(dateOfBirthParts[dateOfBirthParts.length - 3].slice(-2))

            if (gender.id.split('_')[gender.id.split('_').length - 1] === '0') {
              if (countDate.toString().length === 1)
                check_parts.push(`00${ countDate.toString()}`)
               else if (countDate.toString().length === 2)
                check_parts.push(`0${ countDate.toString()}`)
               else
                check_parts.push((parseInt(countDate)).toString())
            } else {
              check_parts.push((parseInt(countDate) + 500).toString())
            }
            return (nicNumber.slice(0, 2) === check_parts[0] && nicNumber.slice(2, 5) === check_parts[1])
          }
        } else if (value.length === 12) {
          check_parts.push(dateOfBirthParts[dateOfBirthParts.length - 3].slice(-4))

          if (gender.id.split('_')[gender.id.split('_').length - 1] === '0') {
            if (countDate.toString().length === 1)
              check_parts.push(`00${ countDate.toString()}`)
             else if (countDate.toString().length === 2)
              check_parts.push(`0${ countDate.toString()}`)
             else
              check_parts.push((parseInt(countDate)).toString())
          } else {
            check_parts.push((parseInt(countDate) + 500).toString())
          }
          return value.slice(0, 4) === check_parts[0] && value.slice(4, 7) === check_parts[1]
        } else {
          return false
        }
      } else {
        return false
      }
    }, gon.translations['errors.messages.nic'])

    const getAge = value => Math.floor((new Date() - new Date(value).getTime()) / 3.15576e+10)

    $.validator.addMethod('minWords', function (value, element, params) {
       return this.optional(element) || value.trim().split(/[\s.,\/\(\)\n]+/).length >= params
    }, gon.translations['errors.messages.minWords'])

    $.validator.addMethod('promo', function (value, element) {
      const regex = /^[a-z0-9]+$/

      return this.optional(element) || regex.test(value)
    }, gon.translations['errors.messages.promo'])

    $.validator.addMethod('minWordsFullName', function (value, element, params) {
      return this.optional(element) || value.trim().split(/[\s.,\/\(\)\n]+/).length >= params
    }, gon.translations['errors.messages.minWordsFullName'])

    $.validator.addMethod('minSymbols', function (value, element, params) {
       return this.optional(element) || value.length >= params
    })

    $.validator.addMethod('fullName', function (value, element, params) {
      const any = currentValue => currentValue === false
      const words = value.trim().split(' ').map(item => {
        return item.length <= params
      })
      return words.every(any)
    }, gon.translations['errors.messages.fullName'])

    $.validator.addMethod('validAddress', function (value, element, params) {
       return this.optional(element) || value.length >= params
    }, gon.translations['errors.messages.address'])

    $.validator.addMethod('validCompany', function (value, element, params) {
       return this.optional(element) || value.length >= params
    }, gon.translations['errors.messages.companyName'])

    $.validator.addMethod('minDate', function (value, element) {
      const minYear = new Date().getFullYear() - 100
      const inputYear = new Date($(element).val()).getFullYear()

      return !(inputYear <= minYear)
    }, gon.translations['errors.messages.invalidDate'])

    $.validator.addMethod('maxDate', function (value, element) {
      const curYear = new Date().getFullYear()
      const inputYear = new Date($(element).val()).getFullYear()

      return !(inputYear >= curYear)
    }, gon.translations['errors.messages.invalidDate'])

    $.validator.addMethod('validAge', function (value, element) {
      return getAge(value) >= 16
    }, gon.translations['errors.messages.age'])

    $.validator.addMethod('salary', function (value, element) {
      const salary = value.replace(/\D/g, '')
      const salary_words_length = salary.length

      return salary_words_length >= 4 && salary_words_length <= 6 || salary == '0'
    }, gon.translations['errors.messages.salary'])

    $.validator.addMethod('fromToLength', function (value, element, params) {
      const phone_numbers = value.replace(/\D+/g, '')

      return params[0] <= phone_numbers.length <= params[1]
    }, 'Should contain from {0} to {1} numbers')

    $.validator.addMethod('fixLength', function (value, element, params) {
      const phone_numbers = value.replace(/\D+/g, '')

      return phone_numbers.length == params[0]
    }, 'Should contain {0} numbers')

    $.validator.addMethod('startedFrom', function (value, element, param) {
      const phone_numbers = value.replace(/\D+/g, '')

      return phone_numbers.substr(0, param.length) == param
    }, 'Should start with \"{0}\"')

    $.validator.addMethod('sameNumbers', function (value, element) {
      if (value == '') return true

      const phone_numbers = value.replace(/\D+/g, '')
      const letter_max = phone_numbers.length - 4
      let no_same_numbers = true
      for (const letter_count in [0.0.letter_max]) {
        if (phone_numbers[letter_count] == phone_numbers[letter_count + 1] &&
            phone_numbers[letter_count] == phone_numbers[letter_count + 2] &&
            phone_numbers[letter_count] == phone_numbers[letter_count + 3] &&
            phone_numbers[letter_count] == phone_numbers[letter_count + 4])
          no_same_numbers = false
      }

      return no_same_numbers
    }, gon.translations['errors.messages.phoneShouldByValid'])

    $.validator.addMethod('dontNumbersConsecutive', function (value, element) {
      if (value == '') return true

      const phone_numbers = value.replace(/\D+/g, '')
      const letter_max = phone_numbers.length - 5
      let no_numbers_consecutive = true
      if (phone_numbers >= 6) {
        for (const letter_count in [0.0.letter_max]) {
          if (phone_numbers.substr(letter_count, 6) == '123456')
            no_numbers_consecutive = false
        }
      }

      return no_numbers_consecutive
    }, gon.translations['errors.messages.phoneShouldByValid'])

    $.validator.addMethod('dontDocIdNumbersConsecutive', function (value, element) {
      if (value == '') return true

      const doc_id_numbers = value.replace(/\D+/g, '')
      const bad_numbers = ['12345', '23456', '34567', '45678', '56789']
      const doc_id_numbers_max = doc_id_numbers.length - 4
      let no_numbers_consecutive = true

      if (doc_id_numbers >= 5) {
        for (letter_count in [0.0.doc_id_numbers_max]) {
          if (doc_id_numbers.substr(letter_count, 5) in bad_numbers)
            no_numbers_consecutive = false
        }
      }

      return no_numbers_consecutive
    })

    $.validator.addMethod('email', function (value, element) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return this.optional(element) || emailRegex.test(value.toLowerCase())
    }, gon.translations['errors.messages.email'])

    $.validator.addMethod('first_name', function (value, element) {
      const regex = /^(((\b[a-zA-Z]{2,40}\b)\s{0,1}){1,5})$/

      return this.optional(element) || regex.test(value.toLowerCase())
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('middle_name', function (value, element) {
      const regex = /^(((\b[a-zA-Z]{1,40}\b)\s{0,1}){1,5})$/

      return this.optional(element) || regex.test(value.toLowerCase())
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('last_name', function (value, element) {
      const regex = /^(((\b[a-zA-Z]{2,40}\b)\s{0,1}){1,5})$/

      return this.optional(element) || regex.test(value.toLowerCase())
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('living_street_house_apt', function (value, element) {
      const regex = /^(((\b[a-zA-Z0-9.,!?]{2,40})\s{0,1}){1,5})$/

      return this.optional(element) || regex.test(value.toLowerCase())
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('company_name', function (value, element) {
      const regex = /^(((\b\w*[A-Za-z]\w{1,}\b)\s{0,1}){1,5})$/

      return this.optional(element) || regex.test(value.toLowerCase())
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('position_in_company', function (value, element) {
      const regex = /^(((\b\w*[A-Za-z]\w{1,}\b)\s{0,1}){1,5})$/

      return this.optional(element) || regex.test(value.toLowerCase())
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('min_length_street', function (value, element) {
      const regex = /^((((\b[a-zA-Z0-9.,!?]{2,40})\s{0,1})){1}.*)$/

      return this.optional(element) || regex.test(value)
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('full_name', function (value, element) {
      const regex_full = /^(((\b[a-zA-Z]{1,40}\b)\s{0,1}){3,5})$/
      const regex = /^(((\b[a-zA-Z]{2,40}\b)\s{0,1}))$/
      const value_array = value.split(' ')
      const first_name = value_array[0]
      const last_name = value_array.slice(-1)[0]
      console.log(last_name)
      return this.optional(element) || (regex_full.test(value) && regex.test(first_name) && regex.test(last_name))
    }, gon.translations['errors.messages.notValid'])

    $.validator.addMethod('fromToLengthLivingHome', function (value, element, params) {
      const phone_numbers = value.replace(/\D+/g, '')

      if (phone_numbers.length > 0)
        return params[0] <= phone_numbers.length <= params[1]
       else
        return true
    }, 'Should contain from {0} to {1} numbers')
  }
}

export default JqueryValidation
