import slider from 'bootstrap-slider';

class CalculatorPDL {
  constructor(target) {
    this.target = target

    this.current_step = null

    this.$amountSlider = $(target).find('@amountSlider')
    this.$amountSliderMove = $(target).find('@amountSliderMove')
    this.$amountSliderMoveToRepeat = $(target).find('@amountSliderMoveToRepeat')
    this.$termSlider = $(target).find('@termSlider')
    this.$termSliderMove = $(target).find('@termSliderMove')

    this.$defaultAmountWas = $(target).find('@defaultAmountWas')
    this.$defaultAmountWasShown = $(target).find('@defaultAmountWasShown')
    this.$defaultAmountChangedTo = $(target).find('@defaultAmountChangedTo')
    this.$maximumAmountShown = $(target).find('@maximumAmountShown')
    this.$maximumAmountShownToRepeat = $(target).find('@maximumAmountShownToRepeat')

    this.$creditLimitation = $(target).find('@creditLimitation')
    this.$totalValue = $(target).find('@totalValue')

    this.$needAmount = $(target).find('@needAmount')
    this.$forTerms = $(target).find('@forTerms')

    this.$calcAmount = $(target).find('@calcAmount')
    this.$calcInterest = $(target).find('@calcInterest')
    this.$calcTotal = $(target).find('@calcTotal')
    this.$paymentDueDateLabel = $(target).find('@paymentDueDateLabel')

    // slider amount plus minus
    this.$minusAmountBtn = $(target).find('@sliderAmountMinus')
    this.$plusAmountBtn = $(target).find('@sliderAmountPlus')

    // slider term plus minus
    this.$minusTermBtn = $(target).find('@sliderTermMinus')
    this.$plusTermBtn = $(target).find('@sliderTermPlus')
  }

  init() {
    const self = this

    if ($(location).attr('href').indexOf("steps/4") >= 0) {
      self.current_step = 4
    }

    self.initializeSliderValues()
    self._setLaonLimitation()
    self.initializeSliders()
    self._countCalcValues()

    self.$minusAmountBtn.off('click').on('click', function(event) {
      self.amountMinus()
      event.preventDefault()
    })

    self.$plusAmountBtn.off('click').on('click', function(event) {
      self.amountPlus()
      event.preventDefault()
    })

    self.$minusTermBtn.off('click').on('click', function(event) {
      self.termMinus()
      event.preventDefault()
    })

    self.$plusTermBtn.off('click').on('click', function(event) {
      self.termPlus()
      event.preventDefault()
    })

    $(self.target).off('click', '@sliderCollapseLink').on('click', '@sliderCollapseLink', function(e) {
      self.$amountSlider.slider('relayout')
      self.$termSlider.slider('relayout')

      $(this).text(function(i, text) {
        if (text == $(this).data('original')) {
          $(this).data('replace')
        } else {
          $(this).data('original')
        }
      })
    })
  }

  initializeSliderValues() {
    this.TERM_VALUES = gon.calculator['term']
    this.TERM_MIN = this.TERM_VALUES[0]
    this.TERM_MAX = this.TERM_VALUES.slice(-1)[0]
    this.LOAN_VALUES = gon.calculator['amount']
    this.LOAN_MIN = this.LOAN_VALUES[0]
    this.LOAN_MAX = this.LOAN_VALUES.slice(-1)[0]
    this.DENOMINATOR = gon.calculator['denominator']
    this.CONDITIONALVALUE = gon.calculator['amountLimitation']
    this.COMMISSIONS = gon.calculator['commissions']

    // this.AMOUNT_STEP = (this.LOAN_MAX - this.LOAN_MIN) / this.LOAN_VALUES.length
    // this.TERM_STEP = (this.TERM_MAX - this.TERM_MIN) / this.TERM_VALUES.length
  }

  _calculatePaymentDueDate() {
    let value = parseFloat(this.$termSlider.val())
    const date = new Date()

    date.setDate(date.getDate() + value)

    value = String((date.getDate())).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + date.getFullYear()

    this.$paymentDueDateLabel.text(value)
  }

  calcPosition(array) {
    var step = 100 / (array.length - 1)
    var positions = [0]
    var i = 0
    while (i < 100) {
      i = i + step
      positions.push(parseInt(i))
    }
    positions.push(100)
    return positions
  }

  initializeSliders() {
    if (this.$amountSlider.length === 0) return;
    this._calculatePaymentDueDate()
    this.initializeAmountSlider()

    if (this.$termSlider.length === 0) return;
    this.initializeTermSlider()
  }

  initializeAmountSlider() {
    const self = this

    if (self.$amountSlider.length > 0) {
      self._calculatePaymentDueDate()
      self.$amountSlider.slider({
        min: self.LOAN_MIN,
        max: self.LOAN_MAX,
        ticks: self.LOAN_VALUES,
        ticks_positions: self._calcPosition(self.LOAN_VALUES),
        tooltip: 'hide',
        formatter: function(value) {
          return self.customToLocaleString(value) + ' LKR'
        }
      }).on('change', function(event) {
        const newValue = self._valueScale(self.LOAN_VALUES, event.value.newValue)

        self.$totalValue.addClass('green')
        self._timeout(self.$totalValue)

        if (newValue > self.CONDITIONALVALUE) {
          self.$creditLimitation.addClass('active')
          self.$creditLimitation.text(gon.translations['js.slider.tip'])
        } else {
          self.$creditLimitation.removeClass('active')
          self.$creditLimitation.text('')
        }

        self.$amountSlider.slider('setValue', newValue)
        self.$needAmount.text(self.customToLocaleString(newValue) + ' ')

        self._calculatePaymentDueDate()
        self._countCalcValues()
      })
    }
  }

  initializeTermSlider() {
    const self = this

    self.$termSlider.slider({
      min: self.TERM_MIN,
      max: self.TERM_MAX,
      ticks: self.TERM_VALUES,
      ticks_positions: self._calcPosition(self.TERM_VALUES),
      tooltip: 'hide',
      formatter: function(value) {
        return value + ' days'
      }
    }).on('change', function(event) {
        const newValue = self._valueScale(self.TERM_VALUES, event.value.newValue)

        self.$termSlider.slider('setValue', newValue)
        self._countCalcValues()
        self._calculatePaymentDueDate()

        self.$totalValue.addClass('green')
        self._timeout(self.$totalValue)

        self.term_slider_move()
    })
  }

  amountPlus() {
    const getValue = parseFloat(this.$amountSlider.val())
    const value = this.LOAN_VALUES[($.inArray(getValue, this.LOAN_VALUES) + 1) % this.LOAN_VALUES.length]

    if (getValue < this.LOAN_VALUES[this.LOAN_VALUES.length - 1]) {
      this.$amountSlider.val(value)
      this.$amountSlider.slider('setValue', value, false, true)
    }
  }

  amountMinus() {
    const getValue = parseFloat(this.$amountSlider.val())
    const value = this.LOAN_VALUES[($.inArray(getValue, this.LOAN_VALUES) - 1 + this.LOAN_VALUES.length) % this.LOAN_VALUES.length]

    if (getValue > this.LOAN_VALUES[0]) {
      this.$amountSlider.val(value)
      this.$amountSlider.slider('setValue', value, false, true)
    }
  }

  termPlus() {
    const getValue = parseFloat(this.$termSlider.val())
    const value = this.TERM_VALUES[($.inArray(getValue, this.TERM_VALUES) + 1) % this.TERM_VALUES.length]

    if (getValue < this.TERM_VALUES[this.TERM_VALUES.length - 1]) {
      this.$termSlider.val(value)
      this.$termSlider.slider('setValue', value, false, true)
    }
  }

  termMinus() {
    const getValue = parseFloat(this.$termSlider.val())
    const value = this.TERM_VALUES[($.inArray(getValue, this.TERM_VALUES) - 1 + this.TERM_VALUES.length) % this.TERM_VALUES.length]

    if (getValue > this.TERM_VALUES[0]) {
      this.$termSlider.val(value)
      this.$termSlider.slider('setValue', value, false, true)
    }
  }

  amount_slider_move_to_repeat() {
    this.$amountSliderMoveToRepeat.val(1)
  }

  amount_slider_move() {
    this.$amountSliderMove.val(1)
  }

  term_slider_move() {
    this.$termSliderMove.val(1)
  }

  _calcPosition(array) {
    const step = 100 / (array.length - 1)
    let positions = [0]
    let i = 0

    while (i < 100) {
      i = i + step
      positions.push(parseInt(i))
    }

    positions.push(100)

    return positions
  }

  _countCalcValues() {
    const amount = parseFloat(this.$amountSlider.val())
    const term = parseFloat(this.$termSlider.val())
    const interest = parseFloat(this.COMMISSIONS[amount][parseFloat(term)]['interestFee'])
    const total = parseFloat(amount + interest)

    this.$calcAmount.text(this.customToLocaleString(amount) + ' ')
    this.$calcInterest.text(this.customToLocaleString(interest) + ' ')
    this.$calcTotal.text(this.customToLocaleString(total) + ' ')
    this.$forTerms.text(term)
  }

  _setLaonLimitation() {
    if (this.current_step) {
      this.LOAN_VALUES = gon.calculator['amount'].filter(num => num <= this.CONDITIONALVALUE)
      this.LOAN_MIN = this.LOAN_VALUES[0]
      this.LOAN_MAX = this.LOAN_VALUES.slice(-1)[0]
    }
  }

  _setDefaultAmountMoved(default_amount_was, new_amount) {
    var new_enum

    if (default_amount_was == new_amount) {
      new_enum = 0
    } else if (default_amount_was < new_amount) {
      new_enum = 1
    } else if (default_amount_was > new_amount) {
      new_enum = -1
    }

    this.$defaultAmountChangedTo.val(new_enum)
  }

  _timeout(totalValue) {
    setTimeout(
      function() {
        $(totalValue).removeClass('green')
      }, 500
    )
  }

  _valueScale(array, value) {
    return array.reduce(function(prev, curr) {
      if (Math.abs(curr - value) < Math.abs(prev - value)) {
        return curr
      } else {
        return prev
      }
    })
  }

  customToLocaleString(value) {
    return value.toLocaleString('lk-LK', {})
  }
}

export default CalculatorPDL;
