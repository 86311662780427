
import jquery_role from 'jquery.role/lib/jquery.role'

class PersonalAccountGA {
  constructor() {
    this.$lkMain = $('html').find('@lkMain')
    this.$bannerMain = $('html').find('@bannerMain')
    this.$takeMoneyMain = $('html').find('@takeMoneyMain')
    this.$loginMobileButton = $('html').find('@loginMobileButton')
  }

  init() {
    this.$lkMain.click(function() {
      if (typeof dataLayer !== 'undefined' && dataLayer !== null) {
        dataLayer.push({
          event: 'event',
          eventCategory: 'Login',
          eventAction: 'lk_main'
        })
      }
    })

    this.$bannerMain.click(function() {
      if (typeof dataLayer !== 'undefined' && dataLayer !== null) {
        dataLayer.push({
          event: 'event',
          eventCategory: 'Login',
          eventAction: 'banner_mains'
        })
      }
    })

    this.$takeMoneyMain.click(function() {
      if (typeof dataLayer !== 'undefined' && dataLayer !== null) {
        dataLayer.push({
          event: 'event',
          eventCategory: 'Login',
          eventAction: 'take_money_main'
        })
      }
    })

    this.$loginMobileButton.click(function() {
      if (typeof dataLayer !== 'undefined' && dataLayer !== null) {
        dataLayer.push({
          event: 'event',
          eventCategory: 'Login',
          eventAction: 'login_mobile_button'
        })
      }
    })
  }
}

export default PersonalAccountGA;
