import 'jquery-ujs'
import 'jquery.role/lib/jquery.role'
import 'jquery-validation'
import 'bootstrap-sass'
import 'inputmask/dist/jquery.inputmask'
import 'slick-carousel'
import SelectTwo from 'SelectTwo'

import JqueryValidation from 'JqueryValidation'
import PersonalAccountGA from 'PersonalAccountGA'
import LibApplicationFields from 'application_fields'

import CalculatorPDL from 'CalculatorPDL'
import CalculatorFLFF from 'Calculator'
const Calculator = gon.pageFlff ? CalculatorFLFF : CalculatorPDL
export default Calculator

const context = function () {
  new SelectTwo()
  new LibApplicationFields($('[role="container"]'))
  new Calculator('@container').init()
  new JqueryValidation().init()
  new PersonalAccountGA().init()

  $('@phoneMobile').inputmask({
    mask: '07 9 999 99 99',
    showMaskOnHover: false,
    greedy: false,
    pos: 3
    })

  const toggle = document.getElementById('promo_link')
  const content = document.getElementById('promo_section')
  const title = document.getElementById('promo_title')
  const promoButton = document.getElementById('promo_button')
  const promoHint = document.getElementById('promo_hint')

  function clickPromoLink() {
    const promo_code_label = document.getElementById('promo_code_label')

    promo_code_label.value = 1
  }

  promoButton.addEventListener('click', function () {
    const resetPromo = document.getElementById('reset_promo')
    resetPromo.value = 1
  })

  toggle.addEventListener('click', function () {
    content.style.display = (content.dataset.toggled ^= 1) ? 'block' : 'none'
    title.style.display = 'none'
    clickPromoLink()
  })

  const $validator = $('@indexForm').validate({
    errorClass: 'form_float__error',
    highlight: function (element, errorClass, validClass) {
      if ($(element).val().length)
        $(element).parent().addClass('move')
       else
        $(element).parent().removeClass('move')

      $(element).closest('.form_float__group').addClass('form_float__group-error')
      $(element).siblings('.form_float__error').remove()
      promoHint.style.display = 'none'
    },
    unhighlight: function (element, errorClass, validClass) {
      if ($(element).val().length)
        $(element).parent().removeClass('move')

      $(element).closest('.form_float__group').removeClass('form_float__group-error')
      $(element).siblings('.form_float__error').remove()
      promoHint.style.display = 'flex'
    },
    rules: {
      'application[promo_code]': {
        required: false,
        maxlength: 20,
        promo: true

      },
      'application[mobile_phone]': {
        required: true,
        mobilePhone: true,
        length_10_letters: true,
        sameNumbers: true,
        dontNumbersConsecutive: true
      }
    }
  })

  const mybutton = document.getElementById('myBtn')

  window.onscroll = function () { scrollFunction() }

  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)
      mybutton.style.display = 'block'
     else
      mybutton.style.display = 'none'
  }

  mybutton.addEventListener('click', function (e) {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })

  $('@phoneMobile')[0].addEventListener('keydown', function (e) {
    const positions = [0, 1, 2, 3]

    if (positions.includes(this.selectionStart)) {
      if (e.key.startsWith('3') || e.key.startsWith('9'))
        e.preventDefault()
    }
  })

  $('@amountSwitcher').on('change', function () {
    const step = parseFloat(this.value)
    const commissions = gon.calculator.commissionsWithFees
    const amount = $('@calcAmount')
    const total = $('@calcTotal')
    const old = $('@calcOldTotal')
    const borrowers = document.querySelector('.borrowers')

    amount.text(step)
    total.text(step)
    old.text(step + parseFloat(commissions[step][10].totalFinanceCharges))

    if (parseInt(this.value, 10) > 15000) {
      amountLogger(this.value)

      $('@amountSwitcher').val(3000).trigger('change')
      borrowers.style.display = 'flex'
    } else {
      borrowers.style.display = 'none'
    }
  })

  const amountLogger = function (data) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: '/blackbox',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      data: {
        data: data
      }
    })
  }

  $('input').on('focusout', function () {
    $validator.element(this)
  })

  $('@phoneMobile').bind('paste', function (e) {
    const pastedData = String(e.originalEvent.clipboardData.getData('text').replace(/\D+/g, ''))
    const mobileInput = $('#application_mobile_phone')
    if (pastedData.startsWith('949') || pastedData.startsWith('943'))
      mobileInput.val(pastedData)
     else if (pastedData.startsWith('07'))
      mobileInput.val(pastedData)
     else if (pastedData.length === 8)
      mobileInput.val(pastedData)
     else
      mobileInput.val(pastedData.substring(2).padStart(10, '0'))
  })

  $('@container').find('.scrolltoelem').click(function (e) {
    let offset_top, self, target
    self = this
    e.preventDefault()
    target = $(e.target).attr('data-scrollto')
    offset_top = $(target).offset().top
    return $('html, body').animate({
      scrollTop: $(target).offset().top,
      scrollTop: offset_top
    }, {
      duration: 600
    })
  })

  $('@container').find('@modal').on('hidden.bs.modal', function () {
    $(this).removeData('bs.modal')
    $(this).find('@modalContent').empty()
  })

  $('@container').on('ajax:success', 'a[data-modal=true]', function (event, data, status, xhr) {
    showModal(data)
  })

  let attempts = 0

  function countAttempts() {
    attempts += 1
    document.getElementById('used_promo_code').value = attempts
  }

  $('@indexForm').on('ajax:success', function (event, data, status, xhr) {
    let errors
    const used_promo_code = document.getElementById('used_promo_code')

    if (data.show_modal) {
      showModal(data.html)
    } else if (data.url) {
      window.location = data.url
    } else if (data.messages) {
      countAttempts()

      let errors = {}

      for (const key in data.messages)
        errors = { [`application[${key}]`]: data.messages[key][0] }

      $validator.showErrors(errors)
      promoHint.style.display = 'none'
    }
  })

  $('@review').not('.slick-initialized').slick({
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true
  })

  $('@creditLimitationClose').click(function () {
    $('@creditLimitation').removeClass('active')
    $('@mainline').removeClass('active')
  })
}

const showModal = function (data) {
  $('@modal @modalContent').html(data)
  $('@modal').modal('show')
}

$(document).ready(context)
