const { localStorage } = window

export default class LibApplicationFields {
  constructor(container) {
    this.container = container
    this.browserStorageList = JSON.parse(localStorage.getItem('browser_storage_list'))

    this.autofill()
  }

  autofill() {
    if (this.browserStorageList === null) return

    const whitelist = [
      'application[mobile_phone]',
      'application[full_name]',
      'application[date_of_birth]',
      'application[gender]',
      'application[salary]',
      'application[email]',
      'application[living_street_house_apt]',
      'application[guarantor_phone]',
      'application[document_number]'
    ]

    whitelist.map(function (field) {
      const el = $(this.container).find(`[name="${field}"]`)
      if (el === undefined) return

      const data = this.browserStorageList[field]
      if (data === undefined) return

      const now = new Date()
      if (Date.parse(now) > Date.parse(data.expiry)) {
        const browserStorageList = JSON.parse(localStorage.getItem('browser_storage_list'))

        delete browserStorageList[field]

        localStorage.setItem('browser_storage_list', JSON.stringify(browserStorageList))
        return
      }

      if (field === 'application[gender]') {
        const radio = $(el)[parseInt(data.value)]
        if (radio === undefined) return

        $(radio).attr('checked', true)
      } else {
        $(el).val(data.value)
      }
    }.bind(this))
  }
}
